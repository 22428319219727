import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { mix } from '@theme-ui/color';
import { PostCard } from '../components/PostCard';
import { Tags } from '../components/Tags';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Divider = makeShortcode("Divider");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const SourceList = makeShortcode("SourceList");
const Card = makeShortcode("Card");
const Heading = makeShortcode("Heading");
const Grid = makeShortcode("Grid");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1><a parentName="h1" {...{
        "href": "#r-g"
      }}>{`R.G.`}</a></h1>
    <p>{`React Web Developer / Staff Software Engineer based in Texas / Made in USA 🇺🇸`}</p>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#intro"
      }}>{`intro`}</a></h2>
    <p>{`Hey there, I'm R.G. 👋! I like to go fast. Particularly, I ❤️ tools, paridigms, & tech that enables developers to move quickly while shipping less bugs. Dive into my world by checking out some of the blogs below 😊`}</p>
    <Divider mdxType="Divider" />
    <Flex sx={{
      flexWrap: 'wrap'
    }} mdxType="Flex">
      <Box sx={{
        width: ["100%", "100%", "55%"],
        mb: 3,
        pr: [0, 0, 4]
      }} mdxType="Box">
        <h2>{`Contact`}</h2>
        <Text mdxType="Text">Twitter: </Text>
        <Link href="https://twitter.com/protoEvangelion" target="_blank" variant="styles.a" mdxType="Link">
    https://twitter.com/protoEvangelion
        </Link>
        <Divider mdxType="Divider" />
        <Text mdxType="Text">GitHub: </Text>
        <Link href="https://github.com/protoEvangelion" target="_blank" variant="styles.a" mdxType="Link">
    https://github.com/protoEvangelion
        </Link>
        <Divider mdxType="Divider" />
        <Text mdxType="Text">Web: </Text>
        <Link href="iamrhino.com" target="_blank" variant="styles.a" mdxType="Link">
  iamrhino.com
        </Link>
        <Divider mdxType="Divider" />
        <Divider mdxType="Divider" />
      </Box>
      <Box sx={{
        width: ["100%", "100%", "45%"],
        mb: 3
      }} mdxType="Box">
        <h2>{`Blog Topics`}</h2>
        <Divider mdxType="Divider" />
        <Tags mdxType="Tags" />
        <Divider mdxType="Divider" />
        <Divider mdxType="Divider" />
      </Box>
    </Flex>
    <h2>{`Projects`}</h2>
    <SourceList filter="projects" mdxType="SourceList">
    {projects => <Flex sx={{
        flexWrap: 'wrap',
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
            {projects.map((edge, index) => {
          const {
            frontmatter: {
              title,
              date,
              url,
              misc
            },
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            mb: 3,
            maxWidth: ['100%', '50%', '33.333%'],
            width: ['100%', '50%', '33.333%']
          }} mdxType="Box">
                        <Link href={slug} sx={{
              textDecoration: 'none'
            }} mdxType="Link">
                            <Card sx={{
                ml: 2,
                mr: 2,
                p: 3
              }} mdxType="Card">
                                <Heading variant="styles.h4" sx={{
                  color: 'text'
                }} mdxType="Heading">
                                    {title}
                                </Heading>
                                <Text sx={{
                  textDecoration: 'underline'
                }} mdxType="Text">View project</Text>
                            </Card>
                        </Link>
                    </Box>;
        })}
        </Flex>}
    </SourceList>
    <h2>{`Latest Posts`}</h2>
    <Divider mdxType="Divider" />
    <SourceList filter="posts" mdxType="SourceList">
    {posts => <Grid sx={{
        gridTemplateColumns: ['1fr', '1fr', '1fr 1fr']
      }} mdxType="Grid">
            {posts.filter(edge => !edge.node.frontmatter.isPrivate).reduce((posts, post) => {
          return post.node.frontmatter.pinned ? [post, ...posts] : [...posts, post];
        }, []).splice(0, 4).map((edge, index) => {
          const {
            featuredImageUrlSharp,
            frontmatter: {
              title,
              tags,
              date,
              dateModified,
              pinned
            },
            excerpt,
            fields: {
              slug
            }
          } = edge.node;
          return <PostCard key={index} title={title} featuredImageUrlSharp={featuredImageUrlSharp} tags={tags} date={date} dateModified={dateModified} excerpt={excerpt} slug={slug} pinned={pinned} mdxType="PostCard" />;
        })}
        </Grid>}
    </SourceList>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Flex sx={{
      justifyContent: 'center'
    }} mdxType="Flex">
    <Box mdxType="Box">
        <Button as="a" variant="styles.buttons.ghost" href="/posts" mdxType="Button">
            More posts ✏️
        </Button>
    </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      